import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Figure from "../../Molecules/Figure";
import Link from "../../Atoms/Link";
import { scaleSpacing, mediaquery, colors } from "../../../../styles/theme";
import RichText from "../../Atoms/RichText";

import {
  OswaldOverline1,
  OswaldOverline2,
  NotoLarge,
} from "../../Atoms/Typography";
import AdBanner from "../../Atoms/AdBanner";

// Wrapper

const Wrapper = styled.section`
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
  margin: 0;

  ${({ hasSpacing, columns }) =>
    hasSpacing &&
    columns === 1 &&
    css`
      margin-bottom: ${scaleSpacing(15)};
    `}

  /*  1 col spacings */
  padding: 0 var(--external-gutter) ${scaleSpacing(16)};
  ${mediaquery.md(css`
    padding: ${scaleSpacing(8)} var(--external-gutter) ${scaleSpacing(25)};
  `)};

  /*  2 cols spacings */
  ${({ columns }) =>
    columns === 2 &&
    css`
      padding: ${scaleSpacing(15)} var(--external-gutter) ${scaleSpacing(20)};
      ${mediaquery.md(css`
        padding: ${scaleSpacing(25)} calc(var(--external-gutter) + var(--col));
      `)}
    `}

  /*  3 cols spacings */
  ${({ columns }) =>
    columns > 2 &&
    css`
      padding: ${scaleSpacing(10)} var(--external-gutter);
      ${mediaquery.md(css`
        padding: ${scaleSpacing(16)} var(--external-gutter);
      `)};
    `}

  ${({ featured }) =>
    featured &&
    css`
      padding: ${scaleSpacing(10)} var(--external-gutter);
      ${mediaquery.md(css`
        padding: ${scaleSpacing(16)} ${scaleSpacing(28)};
      `)};
    `}

  ${({ hasSpacing }) =>
    !hasSpacing &&
    css`
      & + & {
        padding-top: 0;
      }
    `}
`;

// Title Container

const TitleContainer = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${scaleSpacing(6)};

  ${mediaquery.sm(css`
    align-items: center;
    flex-direction: row;
  `)};
`;

// Title

const Title = styled.h2`
  color: ${({ color }) => color};
  ${OswaldOverline2};

  ${mediaquery.md(css`
    ${OswaldOverline1};
  `)};
`;

const RichTitle = styled(RichText)`
  color: ${({ color }) => color};
  ${OswaldOverline2};

  ${mediaquery.md(css`
    ${OswaldOverline1};
  `)};
`;

// More link

const MoreLink = styled(Link)`
  ${NotoLarge};
  line-height: ${scaleSpacing(4)};
  margin: ${scaleSpacing(4)} 0 0 0;

  ${mediaquery.sm(css`
    margin: 0 0 0 ${scaleSpacing(10)};
  `)};
`;

// List

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  /* 1 col spacings */
  gap: ${scaleSpacing(16)};

  ${mediaquery.md(css`
    gap: ${scaleSpacing(22)};
  `)};

  /* 2 col spacings */
  ${({ columns }) =>
    columns === 2 &&
    css`
      gap: ${scaleSpacing(16)};

      ${mediaquery.md(css`
        gap: ${scaleSpacing(12)};
      `)};
    `}

  /* 3 col spacings */
  ${({ columns }) =>
    columns > 2 &&
    css`
      gap: ${scaleSpacing(8)};

      ${mediaquery.md(css`
        gap: ${scaleSpacing(8)};
      `)};
    `}

  ${({ columns }) =>
    columns > 1 &&
    css`
      ${mediaquery.sm(css`
        grid-template-columns: repeat(${columns - 2}, 1fr);
      `)}

      ${mediaquery.md(css`
        grid-template-columns: repeat(${columns - 1}, 1fr);
      `)}

      ${mediaquery.l(css`
        grid-template-columns: repeat(${columns}, 1fr);
      `)}
    `}

  ${({ hasSpacing }) =>
    !hasSpacing &&
    css`
      padding-bottom: 0;
    `};
`;

const FixedItem = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  ${({ col, row, fixedPushMobilePosition }) =>
    col &&
    row &&
    fixedPushMobilePosition &&
    css`
      grid-column: 0 / 1;
      grid-row: ${fixedPushMobilePosition} / ${fixedPushMobilePosition + 1};
      margin: 0 calc(var(--external-gutter) * -1);
      ${mediaquery.md(css`
        margin: 0;
        height: 100%;
        grid-column: ${col - 2} / ${col - 1};
        grid-row: ${row + 1} / ${row + 2};
      `)}

      ${mediaquery.l(css`
        grid-column: ${col} / ${col + 1};
        grid-row: ${row} / ${row + 1};
      `)}
    `};
`;

const FigureList = ({
  title,
  headingTitle,
  items,
  link,
  linkLabel,
  columns,
  as,
  bgColor,
  color,
  fixedPushEnable,
  hasTagInfos,
  showIcon,
  showDescription,
  showMeta,
  showWebsiteLabel,
  hasSpacing,
  split,
  ads,
  featured,
}) => {
  return (
    <Wrapper
      columns={columns}
      bgColor={bgColor}
      featured={featured}
      hasSpacing={hasSpacing}
    >
      {(title || headingTitle) && (
        <TitleContainer>
          {title && <Title color={color}>{title}</Title>}
          {headingTitle && <RichTitle color={color} data={headingTitle} />}
          {linkLabel && link?.url && (
            <MoreLink link={link}>{linkLabel}</MoreLink>
          )}
        </TitleContainer>
      )}
      <List columns={columns} hasSpacing={hasSpacing}>
        {!!items &&
          items.map((item, id) => {
            const small = split && id > 1;
            return (
              <Figure
                as={as}
                {...item}
                key={item?.uid}
                columns={columns}
                bgColor={bgColor}
                color={color}
                hasTagInfos={hasTagInfos}
                showIcon={small ? false : showIcon}
                showDescription={small ? false : showDescription}
                showMeta={showMeta}
                showWebsiteLabel={showWebsiteLabel}
                small={split && small}
                split={split}
                featured={featured}
              />
            );
          })}
        {fixedPushEnable && items?.length >= 2 && (
          <FixedItem row={1} col={3} fixedPushMobilePosition={3}>
            <AdBanner config={{ ...ads, smartadserver_format_id: 96774 }} />
          </FixedItem>
        )}
      </List>
    </Wrapper>
  );
};

FigureList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  headingTitle: PropTypes.array,
  link: PropTypes.object,
  linkLabel: PropTypes.string,
  as: PropTypes.string,
  tagStyles: PropTypes.object,
  bgColor: PropTypes.string,
  columns: PropTypes.number,
  color: PropTypes.string,
  fixedPushEnable: PropTypes.bool,
  hasTagInfos: PropTypes.bool,
  showIcon: PropTypes.bool,
  showDescription: PropTypes.bool,
  showMeta: PropTypes.bool,
  showWebsiteLabel: PropTypes.bool,
  hasSpacing: PropTypes.bool,
  split: PropTypes.bool,
  featured: PropTypes.bool,
  ads: PropTypes.object,
};
FigureList.defaultProps = {
  bgColor: colors.darkgrey2,
  color: colors.white,
  columns: 1,
  fixedPushEnable: false,
  hasTagInfos: false,
  showIcon: true,
  showDescription: true,
  showMeta: true,
  showWebsiteLabel: false,
  hasSpacing: true,
  split: false,
};

export default React.memo(FigureList);
